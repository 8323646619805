import React from 'react';
import Article from './Article';

const ArticleList = ({articleListItems, addToSelectedList, removeFromSelectedList, identifier}) => {

    return (
        <>
            <ul className="article-list-small" data-list-content={identifier}>
                {articleListItems.map(articleListItem => {
                    return (
                        <Article article={articleListItem} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} />
                    )
                })}
            </ul>
        </>
    )
}

export default ArticleList;