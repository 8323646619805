import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Columns, Message, Progress } from 'react-bulma-components';
import ReturnsHeader from './ReturnsHeader';
import FormInput from '../../atoms/FormInput';
import ArticleList from '../../ArticleList';

const ReturnsStep3 = () => {

    // set document title
    useEffect(() => {
        document.title = 'Retoure/Servicefall erfassen - Maison Truffe';
    }, []);

    const navigate = useNavigate();
    
    // read selected articles from cookie
    const selectedArticlesForReturn = [];
    const COOKIE_NAME = 'return-articles';
    const cookies = document.cookie.split(';')
    let articlesCookie = '';
    cookies.forEach((cookie) => {
        if(cookie.trim().startsWith(COOKIE_NAME)){
            articlesCookie = cookie.trim().replace(`${COOKIE_NAME}=`,"");
        }
    });
    if(articlesCookie) {
        let articlesJSON = JSON.parse(articlesCookie);
        articlesJSON.forEach(article => {
            selectedArticlesForReturn.push(article)
        });
    }

    const [ selectedArticles, setSelectedArticles ] = useState(selectedArticlesForReturn);

    

    
    const addToSelectedList = (id) => {}

    const removeFromSelectedList = (id) => {}


    const [values, setValues] = useState({
        fieldMail: 'meine-mailadresse@alexanderh.ch'
    });

    const inputs = [
        {
            id: 1,
            name: 'fieldMail',
            type: 'email',
            label: 'E-Mail-Adresse für die weitere Korrespondenz',
            placeholder: 'Ihre E-Mail-Adresse...',
            formHelp: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            required: true
        },
        {
            id: 2,
            name: 'fieldPackages',
            type: 'number',
            label: 'Anzahl Pakete, welche Sie retournieren',
            placeholder: 'Anzahl Pakete...',
            formHelp: 'Bitte geben Sie die Anzahl Pakete ein, welche Sie retournieren werden',
            additionalDescription : 'Bitte geben Sie in diesem Feld an, wie viele Pakete Sie an uns zurückschicken. Diese Information benötigen wir, um die richtige Anzahl Retourenscheine zu erstellen.',
            required: true
        }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value})
    }

    const returnsStep1CookieName = 'return-reason';
    let returnsStep1Cookie = '';
    let returnReasonText = '';
    cookies.forEach((cookie) => {
        if(cookie.trim().startsWith(returnsStep1CookieName)){
            returnsStep1Cookie = cookie.trim().replace(`${returnsStep1CookieName}=`,"");
        }
    });
    if(returnsStep1Cookie) {
        const returnStep1 = JSON.parse(`${returnsStep1Cookie}`);
        returnReasonText = returnStep1.text;
    }

    function checkAndSubmitStep() {

        const data = new FormData(document.getElementById('contactData'));
        const dataObject = Object.fromEntries(data.entries());

        let errorMessage = document.querySelector('.summary-error-message');

        const emailInput = dataObject.fieldMail;
        const passwordInput = dataObject.fieldPackages;

        if(emailInput === '' || passwordInput === '') {
            errorMessage.removeAttribute('style');
        } else {
            navigate('/account/retouren/4/');
        }
    }

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <ReturnsHeader/>

                    <h2>Schritt 3: Zusammenfassung</h2>
                    <Progress max={9} value={9} color="info"></Progress>
                    <p>Bitte kontrollieren und vervollständigen Sie die nachfolgenden Angaben und speichern Sie die Retoure.</p>
                    
                    <h3>Kontaktdaten</h3>
                    <form id="contactData" method="post" onSubmit={handleSubmit}>
                        <Columns>
                            {inputs.map((input) => (
                                <Columns.Column>
                                    <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                                </Columns.Column>
                            ))}
                        </Columns>
                        </form>
                    <br/>
                    
                    <h3>Grund für die Retoure</h3>
                    <p>{returnReasonText}</p>
                    <br/>
                    <h3>Zu retournierende Artikel</h3>
                    <ArticleList articleListItems={selectedArticles} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} identifier="selectedForReturn" />
                    <ul id="selected-articles"></ul>
                    <br/><br/>


                    <Message color="danger" className="summary-error-message" style={{display: 'none'}}>
                        <Message.Body>Bitte füllen Sie alle Pflichtfelder aus.</Message.Body>
                    </Message>
                    <Button.Group align="right">
                        <Link to='/account/retouren/2/' className="button">zurück</Link>
                        <Button color="primary" onClick={checkAndSubmitStep}>Retoure aufgeben</Button>
                    </Button.Group>
                </div>
            </section>
        </>
    )
}

export default ReturnsStep3;