import React, { Component } from 'react';
import { Button } from 'react-bulma-components';
import { Link } from 'react-router-dom';

class Returns extends Component {

    constructor() {
        super();
        this.toggleState = 'closed';
    }

    // close or open all articles at once
    toggleAllArticles = (e) => {
        this.articles = document.querySelectorAll('.accordion-wrapper > article');
        Array.from(this.articles).forEach(article => {
            this.toggleState === 'closed' ? article.classList.add('is-open') : article.classList.remove('is-open');
        });
        
        if(this.toggleState === 'closed') {
            this.toggleState = 'opened';    
            e.currentTarget.setAttribute('data-status', 'opened');
        } else {
            this.toggleState = 'closed';
            e.currentTarget.setAttribute('data-status', 'closed');
        }
    }

    // close or open single article
    toggleArticle = (e) => {
        let article = e.currentTarget.closest('article');
        if(article) {
            article.classList.toggle('is-open');
        }
    }

    render() {

        // set document title
        document.title = 'Retouren - Service - Maison Truffe';

        return (
            <>
                <section className="section-wrapper">
                    <div className="section-inner">
                        <h1>Hilfe & Service: Retouren</h1>
                        <p>Auf dieser Seite finden Sie alle nützlichen Informationen rund um den Retouren-Prozess.</p>
                        <br/>
                        <Button data-action="toggle-all" data-status="closed" onClick={this.toggleAllArticles}>
                            <span className="text-open">+ Alle aufklappen</span>
                            <span className="text-close">- Alle schliessen</span>
                        </Button>
                        <br/><br/>

                        <div className="accordion-wrapper">
                            <article>
                                <h2 onClick={this.toggleArticle}>Welche Artikel sind Retouren-berechtigt?<span></span></h2>
                                <div className="article-text">
                                    <p>Grundsätzlich alle Artikel, welche Sie innerhalb der letzten 30 Tage gekauft haben (massgebend ist das Datum auf der Rechnung). Ausgenommen sind folgende Artikel:</p>
                                    <ul>
                                        <li>Artikel, welche speziell für Sie angefertigt wurden (z.B. mit eigenen Gravuren)</li>
                                        <li>Aktionsartikel</li>
                                        <li>Lebensmittel</li>
                                    </ul>
                                </div>
                            </article>
                            <article>
                                <h2 onClick={this.toggleArticle}>Wie lange kann ich meinen bestellten Artikel retournieren?<span></span></h2>
                                <div className="article-text">
                                    <p>Grundsätzlich gilt eine Rückgabefrist von 30 Tagen. Diese läuft ab dem Datum des Versands respektive der Abholung.</p>
                                    <p>Für die Rückgabe ist das Datum des Versandstempels oder der persönlichen Rückgabe massgebend.</p>
                                </div>
                            </article>
                            <article>
                                <h2 onClick={this.toggleArticle}>Wie kann ich eine Retoure tätigen?<span></span></h2>
                                <div className="article-text">
                                    <p>Bitte erfassen Sie Ihre Retoure ausschliesslich über unseren Online-Shop. Bitte loggen Sie sich hierfür ein. Falls Sie noch kein Konto haben, können Sie hier ein neues Konto erstellen.</p>
                                    <p>Öffnen Sie anschliessend den Punkt “Retouren” in Ihrem Account. Sie werden anschliessend Schritt für Schritt durch den Retouren-Prozess geführt.</p>
                                </div>
                            </article>
                            <article>
                                <h2 onClick={this.toggleArticle}>Wer übernimmt die Kosten für die Retouren?<span></span></h2>
                                <div className="article-text">
                                    <p>Grundsätzlich übernimmt die Maison Truffe AG die Kosten, welche anfallen, damit Sie die Artikel zu uns retournieren können.</p>
                                </div>
                            </article>
                            <article>
                                <h2 onClick={this.toggleArticle}>Erhalte ich eine Ersatzlieferung oder das Geld zurück?<span></span></h2>
                                <div className="article-text">
                                    <p>Je nach Retouren-Grund erhalten Sie entweder eine Ersatzlieferung (falls die Lieferung defekt war) oder den vollen oder einen Teil des Bestellwerts in Form eines Gutscheins, welchen Sie bei Ihrer nächsten Bestellung einsetzen können. Bitte lassen Sie sich durch den Retouren-Prozess leiten, um Ihre  individuelle Gutschrift zu ermitteln.</p>
                                </div>
                            </article>
                            <article>
                                <h2 onClick={this.toggleArticle}>Ich habe eine Bestellung als Gast getätigt oder einen Artikel im Laden gekauft - wie kann ich eine Retoure tätigen?<span></span></h2>
                                <div className="article-text">
                                    <p>Bitte eröffnen Sie ein reguläres Kundenkonto, um Ihre Retoure aufzugeben.</p>
                                </div>
                            </article>
                        </div>
                        <br/><br/>
                        <Link to="/account/retouren/" className="button is-info" color="primary">Meine Retouren ansehen</Link>&nbsp;
                        <Link to="/account/retouren/1/" className="button is-info" color="primary">Retouren-Prozess starten</Link>
                        <br/><br/>
                    </div>
                </section>
                <section className="section-wrapper">
                    <div className="section-inner">
                        <h2>Noch nicht alle Fragen beantwortet?</h2>
                        <p>
                        Falls Sie noch weitere Unterstützung benötigen, so nehmen Sie bitte mit uns telefonisch oder per E-Mail Kontakt auf. Gerne hilft Ihnen unser Kundendienst weiter. Bitte geben Sie uns falls möglich Ihre Kunden- oder Bestellnummer bekannt.
                        </p>
                        <p>
                            <Link to="mailto:kundendienst@maisontruffe.ch">kundendienst@maisontruffe.ch</Link><br/>
                            <Link to="tel:+41 44 928 25 25">+41 44 928 25 25</Link>
                        </p>
                    </div>
                </section>
            </>
        )
    }
}

export default Returns;