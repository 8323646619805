import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown } from 'react-bulma-components';
import Image from '../../../images/kuhn-rikon-schneidbrett.jpg';

const Order1 = () => {

    // set document title
    useEffect(() => {
        document.title = 'Bestellungen 83727 - Maison Truffe';
    }, []);

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/account/bestellungen/">Meine Bestellungen</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/bestellungen/83727">Bestellung 83727</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Bestellung 83727</h1>

                    <b>Bestellatum:</b> 13.06.2023<br/>
                    <b>Anzahl Artikel:</b> 1<br/>
                    <b>Total:</b> CHF 29.90<br/>
                    <br/><br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Bild</th>
                                <th>Artikel</th>
                                <th>Versanddatum</th>
                                <th align="right">Preis pro Stück in CHF</th>
                                <th>Anzahl</th>
                                <th align="right">Total in CHF</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={Image} alt="Bild" width="150px"/></td>
                                <td>
                                    Kuhn Rikon<br/>
                                    <strong>Schneidebrett Bambus</strong>
                                </td>
                                <td>14.06.2023<br/>
                                Retournierbar bis: 14.07.2023</td>
                                <td align="right">29.90</td>
                                <td>1</td>
                                <td align="right">29.90</td>
                                <td>
                                    <Button title="In den Warenkorb"><span class="material-symbols-outlined">shopping_basket</span></Button>
                                    &nbsp;
                                    <Dropdown closeOnSelect={true} value="" title="Weitere Aktionen" label={<span className="material-symbols-outlined">more_vert</span>}>
                                        <Dropdown.Item >
                                            <Link to="/account/retouren/1/">Artikel zurückgeben</Link>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="right"><strong>Total: CHF 29.90</strong></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Order1;