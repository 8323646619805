import React, {Component} from 'react';
import { Form, Icon} from 'react-bulma-components';
import ArticleList from './../ArticleList';


const ArticleSearch = ({articleListItems, addToSelectedList, removeFromSelectedList, setSearchdArticles}) => {

    /*
    constructor() {
        super();
        this.resultVisibleCssClass = 'result-visible';
        this.articleSearchSelector = '.article-search'
        this.searchArray = [
            {
                'searchTerms' : ['tee', 'teekanne', 'kanne'],
                'title' : 'Retouren Info-Seite',
                'href' : '/service/retouren/'
            }
        ]

        document.addEventListener('click', (e) => {
            if(!e.target.closest(this.articleSearchSelector)) {
                this.removeVisibleClass();
            }
        });

    }

    handleChange(e) {
        let searchTerm = e.currentTarget.value.toLowerCase();

        if(searchTerm === '') {
            this.removeVisibleClass();
        } else {
            let searchResults = 0;
            let searchResultArray = [];
            this.searchArray.forEach(arrayItem => {
                if(arrayItem.searchTerms.includes(searchTerm)) {
                    searchResults++;
                    searchResultArray.push(arrayItem);
                }
            });

            this.displaySearchResult(searchResults, searchResultArray);
        }
    }

    displaySearchResult(resultCount, results) {
        if(!this.headerSearch) {
            this.headerSearch = document.querySelector(this.articleSearchSelector);
        }
        
        if(!this.headerSearchResult) {
            this.headerSearchResult = document.createElement('ul');
            this.headerSearchResult.classList.add('search-result');
            this.headerSearch.appendChild(this.headerSearchResult);
        }

        this.headerSearchResult.innerHTML = '';

        if(resultCount === 0) {
            let resultEmpty = document.createElement('li');
            resultEmpty.innerHTML = 'Keine passenden Treffer gefunden...';
            this.headerSearchResult.appendChild(resultEmpty);
        } else {
            results.forEach(result => {
                let resultItem = document.createElement('li');
                let resultHref = document.createElement('a');
                resultHref.setAttribute('href', result.href);
                resultHref.innerHTML = result.title;
                resultItem.appendChild(resultHref);
                this.headerSearchResult.appendChild(resultItem);
            });
        }

        this.headerSearch.classList.add(this.resultVisibleCssClass);
    }

    removeVisibleClass = () => {
        if(!this.headerSearch) {
            this.headerSearch = document.querySelector(this.articleSearchSelector);
        }

        this.headerSearch.classList.remove(this.resultVisibleCssClass);

    }
    */

    const searchResult = [];

    const handleChange = (e) => {

        const searchResult = [];
        const input = e.currentTarget;
        input.disabled = true;
        const controlWrapper = e.currentTarget.closest('.control');
        if(controlWrapper) {
            controlWrapper.classList.add('search-is-loading');
        }

        setTimeout( function() {  
            let article = articleListItems.find(article => article.keywords.includes(input.value.toLowerCase()));
            if(article) {
                searchResult.push(article);
                setSearchdArticles(searchResult);
                console.log('Artikel gefunden!');
            }
            articleListItems.forEach(articleListItem => {
                searchResult.includes(articleListItem) ? articleListItem.hidden = 'false' : articleListItem.hidden = 'true';
            });

            controlWrapper.classList.remove('search-is-loading');
            input.disabled = false;
            input.focus();
        }.bind(input, controlWrapper), 200);
    }

    return (
        <>
            <Form.Field>
                <Form.Label for="article-search-input-field">Suche</Form.Label>
                <Form.Control className="article-search">
                    <Form.Input placeholder="Suche nach Artikel, Marke, EAN, ..." name="search-input-field" id="article-search-input-field" onChange={handleChange}/>
                    <Icon align="right" size="small">
                        <span className="material-symbols-outlined search-icon">search</span>
                        <span className="material-symbols-outlined refresh-icon">refresh</span>
                    </Icon>
                </Form.Control>
            </Form.Field>
            <ArticleList articleListItems={articleListItems} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} identifier="articleSearch" />
        </>
    )

}

export default ArticleSearch;