import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {

    const setActiveStatus = (e) => {
        e.currentTarget.classList.toggle('is-open');
    };

    return (
        <footer id="footer" aria-describedby="footer-title">
            <h1 className="visuallyhidden" id="footer-title">Links Fusszeile</h1>
            <div className="footer-wrapper">
                <div className="footer-inner">
                    <div className="footer-cols">
                        <div className="footer-col">
                            <h2 className="footer-title" onClick={setActiveStatus}>Unternehmen</h2>
                            <div className="footer-content">
                                <p>
                                Maison Truffe AG<br/>
                                Laubisrütistrasse 52 <br/>
                                CH-8712 Stäfa
                                </p>
                                <p>
                                    <a href="tel:+41 44 928 25 25">+41 44 928 25 25</a><br/>
                                    <a href="mailto:kundendienst@maisontruffe.ch">kundendienst@maisontruffe.ch</a>
                                </p>
                                <p>
                                    <Link to="/kontakt/">Kontaktformular öffnen</Link>
                                </p>
                            </div>
                        </div>
                        <div className="footer-col">
                            <h2 className="footer-title" onClick={setActiveStatus}>Hilfe & Service</h2>
                            <div className="footer-content">
                               <nav>
                                <ul>
                                    <li><NavLink to="/service/faq/">Häufig gestellte Fragen</NavLink></li>
                                    <li><NavLink to="/service/versand/">Versand</NavLink></li>
                                    <li><NavLink to="/service/zahlungsarten/">Zahlungsarten</NavLink></li>
                                    <li><NavLink to="/service/retouren/">Retouren</NavLink></li>
                                </ul>
                               </nav>
                            </div>
                        </div>
                        <div className="footer-col">
                            <h2 className="footer-title" onClick={setActiveStatus}>AGB & Co.</h2>
                            <div className="footer-content">
                               <nav>
                                <ul>
                                    <li><NavLink to="/legal/agb/">AGB</NavLink></li>
                                    <li><NavLink to="/legal/datenschutzerklaerung/">Datenschutzerklärung</NavLink></li>
                                    <li><NavLink to="/legal/impressum/">Impressum</NavLink></li>
                                </ul>
                               </nav>
                            </div>
                        </div>
                        <div className="footer-col">
                            <h2 className="footer-title" onClick={setActiveStatus}>Social Media</h2>
                            <div className="footer-content">
                               <nav>
                                <ul>
                                    <li><Link to="/">Instagram</Link></li>
                                    <li><Link to="/">LinkedIn</Link></li>
                                    <li><Link to="/">TikTok</Link></li>
                                </ul>
                               </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;