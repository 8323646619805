import React, { Component } from 'react';
import { Button } from 'react-bulma-components';


class SearchButton extends Component {
    constructor() {
        super();
        this.searchOpenClass = 'search-is-open';
        this.hasClickEventListener = false;
    }

    toggleSearch = (e) => {
        this.headerWrapper = document.querySelector('.header-wrapper');
        console.log('clicked');
        if(this.headerWrapper) {
            this.headerWrapper.classList.add(this.searchOpenClass);

            this.searchInput = this.headerWrapper.querySelector('.header-search input[name="search-input-field"]')
            if(this.searchInput) {
                this.searchInput.focus();
            }

            if(!this.hasClickEventListener) {
                this.hasClickEventListener = true;
                document.addEventListener('click', (e) => {
                    if(!e.target.closest('.header-search') && !e.target.closest('.button-search')) {
                        this.headerWrapper.classList.remove(this.searchOpenClass);
                    }
                });
            }
        }
    }

    fireSearch = (e) => {
        console.log('input');
    }

    render() {
        return (
            <Button color="" title="Suche öffnen" className="button-search" onClick={this.toggleSearch}>
                <span className="material-symbols-outlined">search</span>
            </Button>
        )
    }
}

export default SearchButton;