import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bulma-components';


const Orders = () => {

    // set document title
    useEffect(() => {
        document.title = 'Meine Bestellungen - Maison Truffe';
    }, []);

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/bestellungen/">Meine Bestellungen</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Meine Bestellungen</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Nummer</th>
                                <th>Bestelldatum</th>
                                <th>Anzahl Artikel</th>
                                <th style={{'align': 'right'}}>Betrag in CHF</th>
                                <th style={{'width': '200px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>83727</td>
                                <td>13.06.2023</td>
                                <td>1</td>
                                <td style={{'align': 'right'}}>29.90</td>
                                <td><Link className="button is-info is-fullwidth" to="/account/bestellungen/83727/">Details öffnen</Link></td>
                            </tr>
                            <tr>
                                <td>82134</td>
                                <td>05.06.2023</td>
                                <td>2</td>
                                <td style={{'align': 'right'}}>401.30</td>
                                <td><Link className="button is-info is-fullwidth" to="/account/bestellungen/82134/">Details öffnen</Link></td>
                            </tr>
                            <tr>
                                <td>63849</td>
                                <td>10.09.2022</td>
                                <td>1</td>
                                <td style={{'align': 'right'}}>142.00</td>
                                <td><Link className="button is-info is-fullwidth" to="/account/bestellungen/63849/">Details öffnen</Link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default Orders;