import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Message, Progress } from 'react-bulma-components';
import ReturnsHeader from './ReturnsHeader';

const ReturnsStep1 = () => {

    // set document title
    useEffect(() => {
        document.title = 'Retoure/Servicefall erfassen - Maison Truffe';
    }, []);

    const returnsStep1CookieName = 'return-reason';
    const cookies = document.cookie.split(';')
    let returnsStep1Cookie = '';
    let selectedValue = 0;
    cookies.forEach((cookie) => {
        if(cookie.trim().startsWith(returnsStep1CookieName)){
            returnsStep1Cookie = cookie.trim().replace(`${returnsStep1CookieName}=`,"");
        }
    });
    if(returnsStep1Cookie) {
        const returnStep1 = JSON.parse(`${returnsStep1Cookie}`);
        selectedValue = returnStep1.value;
    }
    

    console.log(selectedValue);

    const returnReasons = [
        {
            id : 1,
            title : 'Defekt beim Erhalt',
            description: 'Einer oder mehrere Artikel meines Kaufs sind nach dem Auspacken defekt.',
            checked : selectedValue === 1 ? true : false
        },
        {
            id : 2,
            title : 'Defekt während Garantiefrist',
            description: 'Ein Artikel hat während der Garantiefrist einen Defekt erlitten.',
            checked : selectedValue === 2 ? true : false
        },
        {
            id : 3,
            title : 'Servicefall / Reparatur nach Garantiefrist',
            description: 'Ich benötige eine Reparatur für ein defektes Gerät ausserhalb der Garantiefrist oder möchte für mein Gerät einen Service durchführen lassen.',
            checked : selectedValue === 3 ? true : false
        },
        {
            id : 4,
            title : 'Falschlieferung',
            description: 'Ich habe einen Artikel erhalten, welchen ich nicht bestellt habe oder habe von einem Artikel mehr geliefert bekommen als ich bestellt habe.',
            checked : selectedValue === 4 ? true : false
        },
        {
            id : 5,
            title : 'Artikel gefällt mir nicht',
            description: 'Mir gefällt ein gekaufter Artikel nicht und ich möchte ihn zurückgeben.',
            checked : selectedValue === 5 ? true : false
        },
        {
            id : 6,
            title : 'Zu viel bestellt',
            description: 'Ich habe aus Versehen zu viel gekauft und möchte einen Teil meiner Ware zurückgeben.',
            checked : selectedValue === 6 ? true : false
        },
        {
            id : 7,
            title : 'Anderer Grund',
            description: 'Bitte nehmen Sie mit unserem Kundendienst Kontakt auf: kundendienst@maisontruffe.ch, +41 44 928 25 25',
            checked : selectedValue === 7 ? true : false
        }
    ];

    
    const navigate = useNavigate();

    function checkAndSubmitStep() {
        const formError = document.querySelector('.form-error');
        const returnReasons = document.querySelectorAll('input[name="return-reason"]');
        let reasonSelected = false;
        Array.from(returnReasons).forEach(returnReason => {
            if(returnReason.checked && returnReason.value !== '7') {
                reasonSelected = true;
                document.cookie = `${returnsStep1CookieName}={"value": ${returnReason.value}, "text": "${returnReason.title}"};path=/`;
            }
        });

        if(reasonSelected) {
            navigate('/account/retouren/2/');
        } else {
            formError.setAttribute('style', 'display: block');
        } 
    }

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">

                    <ReturnsHeader/>

                    <h2>Schritt 1: Grund für die Retoure auswählen</h2>
                    <Progress max={9} value={3} color="info"></Progress>
                    <p>Bitte wählen Sie aus nachfolgender Liste den Grund für Ihre Retoure:</p>
                    <ul className="selection-list">
                        {
                        returnReasons.map((returnReason) => (
                                <li key={returnReason.id}>
                                    <input type="radio" name="return-reason" value={returnReason.id} id={'return-reason-' + returnReason.id} title={returnReason.title} defaultChecked={returnReason.checked}/>
                                    <label htmlFor={'return-reason-' + returnReason.id}>{returnReason.title}</label>
                                    <p>{returnReason.description}</p>
                                </li>
                        ))
                        }
                    </ul>
                    <Message color="danger" className="form-error" style={{display: 'none'}}>
                        <Message.Body>Bitte wählen Sie einen Grund aus.</Message.Body>
                    </Message>
                    <Button.Group align="right">
                        <Link to='/account/retouren/' className="button">zurück</Link>
                        <Button color="info" onClick={checkAndSubmitStep}>Speichern & Weiter</Button>
                    </Button.Group>


                </div>
            </section>
        </>
    )

}

export default ReturnsStep1;