import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'react-bulma-components';

const Offcanvas = () => {
    return (
        <div className="offcanvas">
            <br/>
            <Menu>
                <Menu.List title="Sortiment">
                    <Menu.List.Item renderAs="a" href="/sortiment/kueche/">Küche</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/sortiment/tisch/">Tisch</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/sortiment/bar/">Bar</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/sortiment/haushalt/">Haushalt</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/sortiment/dekoration/">Dekoration</Menu.List.Item>
                </Menu.List>
                <br/>
                <Menu.List title="Account">
                    <Menu.List.Item>Einstellungen</Menu.List.Item>
                    <Menu.List.Item>Passwort ändern</Menu.List.Item>
                    <Menu.List.Item>Lieferadressen</Menu.List.Item>
                    <Menu.List.Item>Rechnungsadressen</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/account/bestellungen/">Meine Bestellungen</Menu.List.Item>
                    <Menu.List.Item renderAs="a" href="/account/retouren/">Retouren & Servicefälle</Menu.List.Item>
                </Menu.List>
            </Menu>
            <br/><br/>
        </div>
    )
}

export default Offcanvas;