import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Login Check
import PrivateRoute from './PrivateRoute/index'

// Components
import Topbar from './components/Topbar';
import Header from './components/Header';
import Footer from './components/Footer';
import Offcanvas from './components/Offcanvas';

// Pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import Locations from './pages/Locations';
import Jobs from './pages/Jobs';
import Login from './pages/verification/Login';

// Pages: Orders
import Orders from './pages/account/Orders';
import Order1 from './pages/account/orders/order-1';
import Order2 from './pages/account/orders/order-2';
import Order3 from './pages/account/orders/order-3';

// Pages: Returns
import Return763972 from './pages/account/returns/return-763972';
import Return382777 from './pages/account/returns/return-382777';
import Return3232377 from './pages/account/returns/return-3232377';
import Return372173 from './pages/account/returns/return-372173';

// Pages : Service Navigation
import Returns from './pages/service/Returns';

// Pages: Account Navigation
import Dashboard from './pages/account/Dashboard';
import ReturnsOverview from './pages/account/ReturnsOverview';
import ReturnsStep1 from './pages/account/ReturnsStep1';
import ReturnsStep2 from './pages/account/ReturnsStep2';
import ReturnsStep3 from './pages/account/ReturnsStep3';
import ReturnsStep4 from './pages/account/ReturnsStep4';

// Styles
import "./styles/css/theme.css";

const App = () => {
  return (
    <>
      <div className="viewport">
        <Topbar />
        <Header />
        <div className="content" id="content">
          <Routes>
            <Route path='/' element ={<Home />} />
            <Route path='/kontakt/' element={<Contact />} />
            <Route path='ueber-uns' element={<AboutUs />} />
            <Route path='standorte' element={<Locations />} />
            <Route path='jobs' element={<Jobs />} />
            <Route path='login' element={<Login />} />
            <Route path='/account/dashboard/' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path='/service/retouren/' element={<Returns />} />
            <Route path='/account/bestellungen/' element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route path='/account/bestellungen/83727/' element={<PrivateRoute><Order1 /></PrivateRoute>} />
            <Route path='/account/bestellungen/82134/' element={<PrivateRoute><Order2 /></PrivateRoute>} />
            <Route path='/account/bestellungen/63849/' element={<PrivateRoute><Order3 /></PrivateRoute>} />
            <Route path='/account/retouren/' element={<PrivateRoute><ReturnsOverview /></PrivateRoute>} />
            <Route path='/account/retouren/1/' element={<PrivateRoute><ReturnsStep1 /></PrivateRoute>} />
            <Route path='/account/retouren/2/' element={<PrivateRoute><ReturnsStep2 /></PrivateRoute>} />
            <Route path='/account/retouren/3/' element={<PrivateRoute><ReturnsStep3 /></PrivateRoute>} />
            <Route path='/account/retouren/4/' element={<PrivateRoute><ReturnsStep4 /></PrivateRoute>} />
            <Route path='/account/retouren/763972/' element={<PrivateRoute><Return763972 /></PrivateRoute>} />
            <Route path='/account/retouren/382777/' element={<PrivateRoute><Return382777 /></PrivateRoute>} />
            <Route path='/account/retouren/3232377/' element={<PrivateRoute><Return3232377 /></PrivateRoute>} />
            <Route path='/account/retouren/372173/' element={<PrivateRoute><Return372173 /></PrivateRoute>} />
          </Routes>
        </div>
        <Footer />
      </div>
      <Offcanvas />
    </>
  )
}

export default App;
