import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReturnsHeader from './ReturnsHeader';
import { Link } from 'react-router-dom';
import { Button, Columns, Message, Progress } from 'react-bulma-components';
import ArticleList from '../../ArticleList';
import ArticleSearch from '../../atoms/ArticleSearch';

function ReturnsStep2() {

    // set document title
    useEffect(() => {
        document.title = 'Retoure/Servicefall erfassen - Maison Truffe';
    }, []);

    const recentlyOrderedArticles = [
        {
            id : 1,
            brand: 'Kuhn Rikon',
            title: 'Schneidebrett Bambus',
            price: 'CHF 29.90',
            source: 'recentlyOrdered',
            img: 'kuhn-rikon-schneidbrett.jpg',
            quantity: 1
        },
        {
            id: 2,
            brand: 'Le Creuset',
            title: 'Bräter oval, blau',
            price: 'CHF 235.50',
            source: 'recentlyOrdered',
            img: 'le_creuset_br_ter_oval-6.jpg',
            quantity: 1
        },
        {
            id: 3,
            brand: 'Zwilling',
            title: 'Fleischmesser 20cm',
            price: 'CHF 82.90',
            source: 'recentlyOrdered',
            img: 'zwilling-fleischmesser.jpg',
            quantity: 1
        }
    ]

    const articlesForSearch = [
        {
            id : 100,
            brand: 'Villeroy & Boch',
            title: 'Artesano Original Teekanne, 1 l, weiß',
            price: 'CHF 142.00',
            source: 'articleSearch',
            img: 'villeryo-boch-teekanne.jpg',
            quantity: 1,
            keywords: ['villeroy', 'boch', 'tee', 'teekanne', 'kanne'],
            hidden: true
        }
    ]

    const selectedArticlesForReturn = [];

    const [ selectedArticles, setSelectedArticles ] = useState(selectedArticlesForReturn);
    
    
    const [ articles, setArticles ] = useState(recentlyOrderedArticles)
    
    const [ searchArticles, setSearchdArticles ] = useState(articlesForSearch);

    const addToSelectedList = (id) => {

        const allArticles = recentlyOrderedArticles.concat(searchArticles);

        let article = allArticles.find(article => article.id === id);
        if(article) {
            selectedArticlesForReturn.push(article);
            setSelectedArticles(selectedArticlesForReturn);
        }

        const noReturnsSelectedMessage = document.querySelector('.return-not-selected');
        if(noReturnsSelectedMessage) {
           if(selectedArticlesForReturn.length > 0) {
                noReturnsSelectedMessage.setAttribute('style', 'display: none;');
            } else {
                noReturnsSelectedMessage.removeAttribute('style');
            }
        }

        let updatedArticleList = recentlyOrderedArticles.filter(article => article.id !== id);
        setArticles(updatedArticleList);
    }

    const removeFromSelectedList = (id) => {


        console.log(selectedArticlesForReturn);

        const tmpArray = selectedArticles;
        let article = tmpArray.find(article => article.id === id);
        if(article) {
            let index = tmpArray.indexOf(article);
            tmpArray.splice(index,1);
            console.log(tmpArray);
            setSelectedArticles(tmpArray);
        }
    }

    const navigate = useNavigate();

    function checkAndSubmitStep() {
        if(selectedArticles.length === 0) {
            let returnError = document.querySelector('.return-error');
            if(returnError) {
                returnError.setAttribute('style', 'display: block;');
            }
        } else {
            let json_str = JSON.stringify(selectedArticles);
            document.cookie = `return-articles=${json_str};path=/`;
            navigate('/account/retouren/3/');
        }
    }

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    
                    <ReturnsHeader/>

                    <h2>Schritt 2: Artikel auswählen</h2>
                    <Progress max={9} value={6} color="info"></Progress>
                    <p>Bitte wählen Sie die Artikel aus, welche Sie retournieren möchten:</p>
                    <br/>

                    <Columns>
                        <Columns.Column>
                            <h3>Zuletzt gekaufte Artikel</h3>
                            <ArticleList articleListItems={articles} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} identifier="currentlyOrdered" />

                            <h3>Manuelle Suche</h3>
                            <div className="article-search">
                                <ArticleSearch articleListItems={searchArticles} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} identifier="currentlyOrdered" setSearchdArticles={setSearchdArticles}/>
                            </div>
                            <p>
                                <b>Artikel nicht gefunden?</b><br/>
                                Bitte nehmen Sie mit unserem Kundendienst Kontakt auf:<br/>
                                <Link to="mailto:kundendienst@maisontruffe.ch">kundendienst@maisontruffe.ch</Link><br/>
                                <Link to="tel:+41 44 928 25 25">+41 44 928 25 25</Link><br/>
                            </p>

                        </Columns.Column>
                        <Columns.Column>
                            <h3>Zu retournierende Artikel</h3>
                            <ArticleList articleListItems={selectedArticles} addToSelectedList={addToSelectedList} removeFromSelectedList={removeFromSelectedList} identifier="selectedArticlesForReturn" />
                            <Message color="warning" className="return-not-selected">
                                <Message.Body>Noch keine Artikel ausgewählt.</Message.Body>
                            </Message>
                        </Columns.Column>
                    </Columns>

                    

                    <Message color="danger" className="return-error" style={{display: 'none'}}>
                                <Message.Body>Bitte wählen Sie mindestens einen Artikel zum Retournieren aus.</Message.Body>
                            </Message>
                    <Button.Group align="right">
                        <Link to='/account/retouren/1/' className="button">zurück</Link>
                        <Button color="info" onClick={checkAndSubmitStep}>Speichern & Weiter</Button>
                    </Button.Group>

                </div>
            </section>
        </>
    )

}

export default ReturnsStep2;