import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ReturnsStep4 = () => {

    // set document title
    useEffect(() => {
        document.title = 'Die Retoure wurde erfolgreich erfasst - Maison Truffe';
    }, []);

    // "delete" cookies
    const returnsStep1CookieName = 'return-reason';
    const returnsStep2CookieName = 'return-articles';
    const cookies = document.cookie.split(';')
    cookies.forEach((cookie) => {
        if(cookie.trim().startsWith(returnsStep1CookieName)){
            document.cookie = `${returnsStep1CookieName}=;Path=/;Max-Age=-99999999;`;  
        }
        if(cookie.trim().startsWith(returnsStep2CookieName)){
            document.cookie = `${returnsStep2CookieName}=;Path=/;Max-Age=-99999999;`;  
        }
    });


    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">

                    <h2>Die Retoure wurde erfolgreich erfasst</h2>
                    <p>Die nächsten Schritte sehen wie folgt aus:</p>
                    <ol>
                        <li>Sie erhalten von uns per E-Mail die Retouren-Scheine im PDF-Format zugeschickt.</li>
                        <li>Senden Sie Ihre Pakete an die auf dem Retouren-Schein aufgedruckte Adresse.</li>
                        <li>Sobald die Retoure bei uns eingetroffen ist, prüfen wir diese.</li>
                        <li>Sie erhalten eine Gutschrift gemäss Bestimmungen in den <Link to="/legal/agb/">Allgemeinen Geschäftsbedingungen</Link>.</li>
                    </ol>
                    <p>Sie können den Status Ihrer Retouren jederzeit unter <Link to='/account/retouren/'>"Meine Retouren & Servicefälle"</Link> überprüfen. Auf der <Link to="/service/retouren/">"Hilfe & Service"-Seite</Link> finden Sie ausserdem detaillierte Informationen über das weitere Vorgehen.</p>
                    <p>
                        <Link to='/account/retouren/' className="button is-info">Weiter zu "Meine Retouren & Servicefälle"</Link>&nbsp;
                        <Link to='/service/retouren/' className="button">"Hilfe & Service" öffnen</Link>
                    </p>
                </div>
            </section>
        </>
    )
}

export default ReturnsStep4;