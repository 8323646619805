import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Columns, Form, Icon } from 'react-bulma-components';

class Contact extends Component {

    
    constructor() {
        super();
        document.title = 'Kontaktformular - Maison Truffe';
    }

    validateFormField = (formField) => {

        let hasError = false;
        let field = formField.closest('.field');

        if(formField.required && formField.value === '') {
            hasError = true;
        }

        if(field) {
            hasError ? field.classList.add('has-error') : field.classList.remove('has-error');

            if(formField.tagName.toLowerCase() === 'select') {
                let selectWrapper = formField.closest('.select');
                if(selectWrapper) {
                    hasError ? selectWrapper.classList.add('is-danger') : selectWrapper.classList.remove('is-danger');
                }
            } else {
                hasError ? formField.classList.add('is-danger') : formField.classList.remove('is-danger');
            }         
        }

        return !hasError ? false : formField;
    }

    validateForm = (e) => {
        e.preventDefault();
        let hasFormError = false;
        let formFields = [];
        let firstFormFieldWithError = undefined;

        if(!this.salutationFormField) {
            this.salutationFormField = e.currentTarget.querySelector('select[name="salutation"]');
        }
        if(!this.firstNameFormField) {
            this.firstNameFormField = e.currentTarget.querySelector('input[name="firstname"]');
        }
        if(!this.lastNameFormField) {
            this.lastNameFormField = e.currentTarget.querySelector('input[name="lastname"]');
        }
        if(!this.emailFormField) {
            this.emailFormField = e.currentTarget.querySelector('input[name="email"]');
        }
        if(!this.messageFormField) {
            this.messageFormField = e.currentTarget.querySelector('textarea[name="message"]');
        }

        formFields.push(this.salutationFormField);
        formFields.push(this.firstNameFormField);
        formFields.push(this.lastNameFormField);
        formFields.push(this.emailFormField);
        formFields.push(this.messageFormField);

        formFields.forEach(formField => {
            if(this.validateFormField(formField)) {
                hasFormError = true;
                if(!firstFormFieldWithError) {
                    firstFormFieldWithError = formField
                }
            }
        });

        if(firstFormFieldWithError) {
            firstFormFieldWithError.focus();
        }

        
        if(!hasFormError) {
            e.currentTarget.submit();
        }
    }

    render() {
        return (
            <>
                <>
                <section className="section-wrapper">
                    <div className="section-inner">
                        <h1>Kontakt</h1>
                        <p>
                            Bei Fragen stehen wir Ihnen gerne telefonisch oder per E-Mail zur Verfügung:
                        </p>
                        <p>
                            <Link to="tel:+41 44 928 25 25">+41 44 928 25 25</Link><br/>
                            <Link to="mailto:kundendienst@maisontruffe.ch">kundendienst@maisontruffe.ch</Link>
                        </p>
                        <p>
                            ... oder nutzen Sie unser Kontaktformular:<br/><br/>
                        </p>
                        <Columns>
                            <Columns.Column>
                                <form method="post" action="" onSubmit={this.validateForm} noValidate>
                                    <Form.Field>
                                        <Form.Label htmlFor="salutation">Anrede</Form.Label>
                                        <Form.Control>
                                            <Form.Select name="salutation" id="salutation" required>
                                                <option value="">Bitte wählen...</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Herr">Herr</option>
                                            </Form.Select>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte wählen Sie einen Wert aus.</Form.Help>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Label htmlFor="firstname">Vorname</Form.Label>
                                        <Form.Control>
                                            <Form.Input type="text" placeholder="Ihr Vorname..." name="firstname" id="firstname" required/>
                                            <Icon align="right" size="small" color="danger">
                                                <span className="material-symbols-outlined">warning</span>
                                            </Icon>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte füllen Sie dieses Feld aus.</Form.Help>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Label htmlFor="lastname">Nachname</Form.Label>
                                        <Form.Control>
                                            <Form.Input type="text" placeholder="Ihr Nachname..." name="lastname" id="lastname" required/>
                                            <Icon align="right" size="small" color="danger">
                                                <span className="material-symbols-outlined">warning</span>
                                            </Icon>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte füllen Sie dieses Feld aus.</Form.Help>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Label htmlFor="phone">Telefon</Form.Label>
                                        <Form.Control>
                                            <Form.Input type="tel" placeholder="Ihre Telefonnummer..." name="phone" id="phone"/>
                                            <Icon align="right" size="small" color="danger">
                                                <span className="material-symbols-outlined">warning</span>
                                            </Icon>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte füllen Sie dieses Feld aus.</Form.Help>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Label htmlFor="email">E-Mail</Form.Label>
                                        <Form.Control>
                                            <Form.Input type="email" placeholder="Ihre E-Mail-Adrese..." name="email" id="email" required/>
                                            <Icon align="right" size="small" color="danger">
                                                <span className="material-symbols-outlined">warning</span>
                                            </Icon>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte füllen Sie dieses Feld aus.</Form.Help>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Label htmlFor="message">Nachricht</Form.Label>
                                        <Form.Control>
                                            <Form.Textarea placeholder="Ihre Nachricht..." name="message" id="message" required></Form.Textarea>
                                            <Icon align="right" size="small" color="danger">
                                                <span className="material-symbols-outlined">warning</span>
                                            </Icon>
                                        </Form.Control>
                                        <Form.Help color="danger">Bitte füllen Sie dieses Feld aus.</Form.Help>
                                    </Form.Field>
                                    <br/>
                                    <Button.Group align="right">
                                        <Button submit="true" color="primary">Absenden</Button>
                                    </Button.Group>
                                </form>
                            </Columns.Column>
                            <Columns.Column>
                            </Columns.Column>
                        </Columns>
                    </div>
                </section>
            </>
            </>
            
        )
    }
}

export default Contact;