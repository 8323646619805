import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown } from 'react-bulma-components';
import Image from '../../../images/le_creuset_br_ter_oval-6.jpg';
import Image2 from '../../../images/zwilling-fleischmesser.jpg';

const Order2 = () => {

    // set document title
    useEffect(() => {
        document.title = 'Bestellungen 82134 - Maison Truffe';
    }, []);

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/account/bestellungen/">Meine Bestellungen</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/bestellungen/83727">Bestellung 82134</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Bestellung 82134</h1>

                    <b>Bestellatum:</b> 05.06.2023<br/>
                    <b>Anzahl Artikel:</b> 2<br/>
                    <b>Total:</b> CHF 401.30<br/>
                    <br/><br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Bild</th>
                                <th>Artikel</th>
                                <th>Versanddatum</th>
                                <th align="right">Preis pro Stück in CHF</th>
                                <th>Anzahl</th>
                                <th align="right">Total in CHF</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={Image} alt="Bild" width="150px"/></td>
                                <td>
                                    Le Creuset<br/>
                                    <strong>Bräter oval, blau</strong>
                                </td>
                                <td>07.06.2023<br/>
                                Retourniebar bis: 07.07.2023</td>
                                <td align="right">235.50</td>
                                <td>1</td>
                                <td align="right">235.50</td>
                                <td>
                                    <Button title="In den Warenkorb"><span class="material-symbols-outlined">shopping_basket</span></Button>
                                    &nbsp;
                                    <Dropdown closeOnSelect={true} value="" title="Weitere Aktionen" label={<span className="material-symbols-outlined">more_vert</span>}>
                                        <Dropdown.Item >
                                            <Link to="/account/retouren/1/">Artikel zurückgeben</Link>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td><img src={Image2} alt="Bild" width="150px"/></td>
                                <td>
                                    Zwilling<br/>
                                    <strong>Fleischmesser 20cm</strong>
                                </td>
                                <td>15.06.2023<br/>
                                Retourniebar bis: 15.07.2023</td>
                                <td align="right">82.90</td>
                                <td>2</td>
                                <td align="right">165.80</td>
                                <td>
                                    <Button title="In den Warenkorb"><span class="material-symbols-outlined">shopping_basket</span></Button>
                                    &nbsp;
                                    <Dropdown closeOnSelect={true} value="" title="Weitere Aktionen" label={<span className="material-symbols-outlined">more_vert</span>}>
                                        <Dropdown.Item >
                                            <Link to="/account/retouren/1/">Artikel zurückgeben</Link>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="right"><strong>Total: CHF 401.30</strong></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Order2;