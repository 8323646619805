import React, { useEffect } from 'react';
import { Card, Columns } from 'react-bulma-components';

// Media
import Stimmungsbild from '../images/startseite-stimmungsbild.jpg';
import Bild1 from '../images/kuhn-rikon-schneidbrett.jpg';
import Bild2 from '../images/le_creuset_br_ter_oval-6.jpg';
import Bild3 from '../images/zwilling-fleischmesser.jpg';
import Bild4 from '../images/villeryo-boch-teekanne.jpg';

const Home = () => {

    // set document title
    useEffect(() => {
        document.title = 'Maison Truffe – die beste Nase für die besten Marken';
    }, []);

    return (
        <section className="section-wrapper">
            <div className="section-inner">
                <h1>Maison Truffe – die beste Nase für die besten Marken</h1>
                <img src={Stimmungsbild} alt="Stimmungsbild" />
                <p>Wir sind das grösste Schweizer Grosshandelsunternehmen und bedeutender Importeur für hochwertige Küchen- und Tischartikel für den privaten Haushalt sowie die professionelle Gastronomie. Mit über 50 motivierten Mitarbeitenden beliefern wir aus unserem eigenen Logistikzentrum über 1’500 Fachgeschäfte, Warenhäuser und Gastroanbieter in der ganzen Schweiz. Maison Truffe vertritt zahlreiche bekannte Marken von über 100 Herstellern im In- und Ausland. Unser umfangreiches Sortiment umfasst zurzeit rund 50‘000 Artikel mit hoher Verfügbarkeit: Von der Zitronenpresse über das Profimesser bis zu preisgekrönten Teekannen. Zum Angebot gehören auch Produkte für die gehobene Wohnkultur, den heutigen Lifestyle zu Hause und Geschenkideen, so z.B. die Kollektion von Easy Life. Zu den bekannten Kitchen und Tableware-Marken, welche Maison Truffe repräsentiert, gehören Staub, de Buyer, Demeyere, Zwilling, Revol, JosephJoseph, Lékué, Arzberg, Cuisipro, Staub, LSA, OXO, Westmark und viele mehr.</p>
                <h2>Unsere Neuheiten</h2>
                <Columns>
                        <Columns.Column>
                            <Card>
                                <Card.Image src={Bild2} alt="Bräter oval"/>
                                <Card.Content>
                                    <div>Le Creuset</div>
                                    <strong>Bräter oval, blau</strong>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                        <Columns.Column>
                            <Card>
                                <Card.Image src={Bild1} alt="Schneidebrett"/>
                                <Card.Content>
                                    <div>Kuhn Rikon</div>
                                    <strong>Schneidebrett Bambus</strong>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                        <Columns.Column>
                            <Card>
                                <Card.Image src={Bild3} alt="Fleischmesser"/>
                                <Card.Content>
                                    <div>Zwilling</div>
                                    <strong>Fleischmesser 20cm</strong>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                        <Columns.Column>
                        <Card>
                                <Card.Image src={Bild4} alt="Teekanne"/>
                                <Card.Content>
                                    <div>Villeroy & Boch</div>
                                    <strong>Artesano Original Teekanne, 1&nbsp;l, weiß</strong>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                </Columns>
            </div>
        </section>
    )
}

export default Home;