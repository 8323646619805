import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown } from 'react-bulma-components';
import Image from '../../../images/kuhn-rikon-schneidbrett.jpg';

const Return763972 = () => {

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/account/retouren/">Retouren & Servicefälle</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/retouren/763972">Retoure 763972</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Retoure Nummer 763972</h1>

                    <b>Retouren-Datum:</b> 15.04.2023<br/>
                    <b>Anzahl Artikel:</b> 1<br/>
                    <b>Status der Retoure:</b> In Bearbeitung<br/>
                    <b>Retouren-Grund:</b> Artikel gefällt mir nicht<br/>
                    <b>E-Mail für Korrespondenz:</b> alexanderhauck@opacc.ch<br/>
                    <b>Anzahl retournierte Pakete:</b> 1<br/>
                    <br/><br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Bild</th>
                                <th>Artikel</th>
                                <th>Menge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={Image} alt="Bild" width="150px"/></td>
                                <td>
                                    Kuhn Rikon<br/>
                                    <strong>Schneidebrett Bambus</strong>
                                </td>
                                <td>3 Stück</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Return763972;