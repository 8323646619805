import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const Topbar = () => {
    return (
        <>
            <h1 className="visuallyhidden">Navigieren im Webshop von Maison Truffe</h1>
            <nav id="skiplinks" aria-describedby="skiplinks-title">
                <h2 id="skiplinks-title" className="visuallyhidden">Schnellnavigation</h2>
                <ul>
                    <li>
                        <a accessKey="0" href="/" title="[ALT + SHIFT + 0]">Startseite</a>
                    </li>
                    <li>
                        <a accessKey="1" href="#topbar" title="[ALT + SHIFT + 1]">Topbar</a>
                    </li>
                    <li>
                        <a accessKey="2" href="#navigation" title="[ALT + SHIFT + 2]">Suche & Navigation</a>
                    </li>
                    <li>
                        <a accessKey="3" href="#content" title="[ALT + SHIFT + 3]">Inhalt</a>
                    </li>
                    <li>
                        <a accessKey="4" href="#footer" title="[ALT + SHIFT + 4]">Footer</a>
                    </li>
                </ul>
            </nav>
            <div className="topbar" id="topbar">
                <div className="topbar-wrapper">
                    <div className="topbar-inner">
                        <nav>
                            <NavLink to='/kontakt/'>Kontakt</NavLink>
                            <NavLink to='/ueber-uns/'>Über uns</NavLink>
                            <NavLink to='/standorte/'>Standorte</NavLink>
                            <NavLink to='/jobs/'>Jobs</NavLink>
                        </nav>
                        <ul className="language-selection">
                            <li><Link to='/' className="is-active">DE</Link></li>
                            <li><Link to='/'>FR</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topbar;