import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown } from 'react-bulma-components';
import Image from '../../../images/le_creuset_br_ter_oval-6.jpg';
import Image2 from '../../../images/zwilling-fleischmesser.jpg';
import Image3 from '../../../images/villeryo-boch-teekanne.jpg';

const Return3232377 = () => {

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/account/retouren/">Retouren & Servicefälle</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/retouren/3232377/">Retoure 3232377</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Retoure Nummer 3232377</h1>

                    <b>Retouren-Datum:</b> 01.03.2023<br/>
                    <b>Anzahl Artikel:</b> 3<br/>
                    <b>Status der Retoure:</b> Abgeschlossen<br/>
                    <b>Retouren-Grund:</b> Artikel gefällt mir nicht<br/>
                    <b>E-Mail für Korrespondenz:</b> alexanderhauck@opacc.ch<br/>
                    <b>Anzahl retournierte Pakete:</b> 2<br/>
                    <br/><br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Bild</th>
                                <th>Artikel</th>
                                <th>Menge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={Image3} alt="Bild" width="150px"/></td>
                                <td>
                                    Villeroy & Boch<br/>
                                    <strong>Artesano Original Teekanne, 1 l, weiß</strong>
                                </td>
                                <td>1 Stück</td>
                            </tr>
                            <tr>
                                <td><img src={Image} alt="Bild" width="150px"/></td>
                                <td>
                                    Le Creuset<br/>
                                    <strong>Bräter oval, blau</strong>
                                </td>
                                <td>1 Stück</td>
                            </tr>
                            <tr>
                                <td><img src={Image2} alt="Bild" width="150px"/></td>
                                <td>
                                    Zwilling<br/>
                                    <strong>Fleischmesser 20cm</strong>
                                </td>
                                <td>1 Stück</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Return3232377;