import { useState } from 'react';
import { Form } from 'react-bulma-components';

const FormInput = (props) => {
    const [focused,setFocused] = useState(false);
    const {label, onChange, id, formHelp, defaultValue, additionalDescription, ...inputProps} = props;

    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
        <Form.Field>
            <Form.Label htmlFor={"field-"+id}>{label}</Form.Label>
            <Form.Control>
                <Form.Input 
                    onChange={onChange}
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    id={"field-"+id}
                    {...inputProps}
                    ></Form.Input>
                <Form.Help color="danger">{formHelp}</Form.Help>
            </Form.Control>
            <p>{additionalDescription}</p>
        </Form.Field>
    )
}

export default FormInput;