import React, {Component} from 'react';
import { Form, Icon} from 'react-bulma-components';


class SearchInput extends Component {

    constructor() {
        super();
        this.resultVisibleCssClass = 'result-visible';
        this.searchArray = [
            {
                'searchTerms' : ['retour', 'retoure', 'retouren', 'retouren-prozess', 'retoure erfassen'],
                'title' : 'Retouren Info-Seite',
                'href' : '/service/retouren/'
            }
        ]

        document.addEventListener('click', (e) => {
            if(!e.target.closest('.header-search')) {
                this.removeVisibleClass();
            }
        });

    }

    handleChange(e) {
        let searchTerm = e.currentTarget.value.toLowerCase();

        if(searchTerm === '') {
            this.removeVisibleClass();
        } else {
            let searchResults = 0;
            let searchResultArray = [];
            this.searchArray.forEach(arrayItem => {
                if(arrayItem.searchTerms.includes(searchTerm)) {
                    searchResults++;
                    searchResultArray.push(arrayItem);
                }
            });

            this.displaySearchResult(searchResults, searchResultArray);
        }
    }

    displaySearchResult(resultCount, results) {
        if(!this.headerSearch) {
            this.headerSearch = document.querySelector('.header-search');
        }
        
        if(!this.headerSearchResult) {
            this.headerSearchResult = document.createElement('ul');
            this.headerSearchResult.classList.add('search-result');
            this.headerSearch.appendChild(this.headerSearchResult);
        }

        this.headerSearchResult.innerHTML = '';

        if(resultCount === 0) {
            let resultEmpty = document.createElement('li');
            resultEmpty.innerHTML = 'Keine passenden Treffer gefunden...';
            this.headerSearchResult.appendChild(resultEmpty);
        } else {
            results.forEach(result => {
                let resultItem = document.createElement('li');
                let resultHref = document.createElement('a');
                resultHref.setAttribute('href', result.href);
                resultHref.innerHTML = result.title;
                resultItem.appendChild(resultHref);
                this.headerSearchResult.appendChild(resultItem);
            });
        }

        this.headerSearch.classList.add(this.resultVisibleCssClass);
    }

    removeVisibleClass = () => {
        if(!this.headerSearch) {
            this.headerSearch = document.querySelector('.header-search');
        }

        this.headerSearch.classList.remove(this.resultVisibleCssClass);

    }

    render = () => {
        return (
            <Form.Field>
                <Form.Label htmlFor="search-input-field">Suche</Form.Label>
                <Form.Control>
                    <Form.Input placeholder="Suche nach Artikel, Marke, EAN, ..." name="search-input-field" id="search-input-field" onChange={this.handleChange.bind(this)}/>
                    <Icon align="right" size="small" style={{'color' : '#FFF'}} aria-hidden="true">
                        <span className="material-symbols-outlined" aria-hidden="true">search</span>
                    </Icon>
                </Form.Control>
            </Form.Field>
        )
    }

}

export default SearchInput;