import React, { Component } from 'react';
import { Button } from 'react-bulma-components';


class OffcanvasButton extends Component {

    constructor() {
        super();
        this.offcanvasState = 'closed';
        this.offcanvasSymbolWrapper = undefined;
        this.htmlWrapper = document.getElementsByTagName('html')[0];
    }

    toggleOffcanvas = (e) => {

        if(this.htmlWrapper) {

            if(!this.offcanvasSymbolWrapper) {
                this.offcanvasSymbolWrapper = e.currentTarget.querySelector('.material-symbols-outlined');
            }

            if(this.offcanvasState === 'closed') {
                this.htmlWrapper.classList.add('offcanvas-open');
                this.offcanvasState = 'open';
                this.offcanvasSymbolWrapper.innerHTML = 'close';
                this.offcanvasSymbolWrapper.setAttribute('title', 'Menu schliessen')
            } else {
                this.htmlWrapper.classList.remove('offcanvas-open');
                this.offcanvasState = 'closed';
                this.offcanvasSymbolWrapper.innerHTML = 'menu';
                this.offcanvasSymbolWrapper.setAttribute('title', 'Menu öffnen')
            }
        } else {
            console.log('HTML-Wrapper is missing');
        }
    }

    render() {
        return (
            <Button color="" title="Menü öffnen" onClick={this.toggleOffcanvas} className="button-offcanvas">
                <span className="material-symbols-outlined">menu</span>
            </Button>
        );
    }
}

export default OffcanvasButton;