import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {

    // check if logged in
    const COOKIE_NAME = 'loginState';
    const cookies = document.cookie.split(';')
    let formCookie = '';
    let isLoggedIn = false;
    cookies.forEach((cookie) => {
        if(cookie.startsWith(COOKIE_NAME)){
            formCookie = cookie.replace(`${COOKIE_NAME}=`,"");
        }
    })
    if(formCookie && formCookie === 'loggedIn') {
        console.log('Eingeloggt.');
        isLoggedIn = true;
    } else {
        console.log('Nicht eingeloggt, Redirect auf Login-Seite.');
    }

    return (
        isLoggedIn ? children : <Navigate to='/login/' />
    )
}

export default PrivateRoute;