import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Columns, Message} from 'react-bulma-components';
import FormInput from '../../atoms/FormInput';

const Login = () => {

    const [values, setValues] = useState({
        username: 'meine-mailadresse@alexanderh.ch',
        password: 'Asdf123'
    });
    
    const inputs = [
        {
            id: 1,
            name: 'username',
            type: 'text',
            label: 'Benutzername',
            placeholder: 'Ihr Benutzername...',
            formHelp: 'Bitte geben Sie Ihren Benutzernamen ein.',
            required: true
        },
        {
            id: 2,
            name: 'password',
            type: 'password',
            label: 'Passwort',
            placeholder: 'Ihr Passwort...',
            formHelp: 'Bitte geben Sie Ihr Passwort ein.',
            required: true
        }
    ];

    const navigate = useNavigate();

    const cookieName = 'loginState';

    const handleSubmit = (e) => {
        
        const loginErrorMessage = document.querySelector('.login-error-message');
        loginErrorMessage.setAttribute('style', 'display: none;');
        
        e.preventDefault();
        const data = new FormData(e.target)
        const dataObject = Object.fromEntries(data.entries());

        const usernameInput = dataObject.username;
        const passwordInput = dataObject.password;

        if(usernameInput === 'meine-mailadresse@alexanderh.ch' && passwordInput === 'Asdf123') {
            document.cookie = `${cookieName}=${'loggedIn'};path=/`
            navigate('/account/dashboard/');
        } else {
            loginErrorMessage.removeAttribute('style');
        }
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value})
    }

    // set document title
    useEffect(() => {
        document.title = 'Login - Maison Truffe';
    }, []);

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Login</h1>
                    <Message color="danger" className="login-error-message" style={{display: 'none'}}>
                        <Message.Header>Login nicht erfolgreich</Message.Header>
                        <Message.Body>Der Benutzername und/oder das Passwort sind nicht korrekt. Bitte versuchen Sie es erneut.</Message.Body>
                    </Message>
                    <Columns>
                        <Columns.Column>
                            <form method="post" onSubmit={handleSubmit}>
                                {inputs.map((input) => (
                                    <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                                ))}
                                <Button.Group align="right">
                                    <Button color="primary" submit="true">Login</Button>
                                </Button.Group>
                                <Link href="#">Passwort vergessen</Link>
                            </form>
                        </Columns.Column>
                        <Columns.Column></Columns.Column>
                    </Columns>
                </div>
            </section>

        </>
    )
}

export default Login;