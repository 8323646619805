import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown } from 'react-bulma-components';
import Image from '../../../images/zwilling-fleischmesser.jpg';

const Return372173 = () => {

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/account/retouren/">Retouren & Servicefälle</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/retouren/372173/">Retoure 372173</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Retoure Nummer 372173</h1>

                    <b>Retouren-Datum:</b> 20.02.2023<br/>
                    <b>Anzahl Artikel:</b> 1<br/>
                    <b>Status der Retoure:</b> Abgeschlossen<br/>
                    <b>Retouren-Grund:</b> Falschlieferung<br/>
                    <b>E-Mail für Korrespondenz:</b> alexanderhauck@opacc.ch<br/>
                    <b>Anzahl retournierte Pakete:</b> 1<br/>
                    <br/><br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Bild</th>
                                <th>Artikel</th>
                                <th>Menge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={Image} alt="Bild" width="150px"/></td>
                                <td>
                                    Zwilling<br/>
                                    <strong>Fleischmesser 20cm</strong>
                                </td>
                                <td>1 Stück</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Return372173;