import React from 'react';
import { Button, Form } from 'react-bulma-components';

const Article = ({article, addToSelectedList, removeFromSelectedList}) => {

    const updateQuantity = (e) => {
        console.log(e);
        article.quantity = e.currentTarget.value;
    }

    return (        
        <>
            <li key={article.id} data-hidden={article.hidden}>
                <article>
                    <div className="article-image">
                        <img src={require('./images/' + article.img)} alt={article.title}/>
                    </div>
                    <div className="article-description">
                        {article.brand}<br/>
                        <strong>{article.title}</strong><br/>
                        {article.price} pro Stück<br/>
                        <div className="article-quantity"><strong>Menge: {article.quantity} Stück</strong></div>
                        <Form.Field>
                            <Form.Label htmlFor={'amount-' + article.id}>zu retournierende Menge</Form.Label>
                            <Form.Control>
                                <Form.Input type="number" placeholder="Menge..." name={'amount-' + article.id} id={'amount-' + article.id} defaultValue={article.quantity} onChange={updateQuantity}/><span>Stück</span>
                            </Form.Control>
                        </Form.Field>
                    </div>
                    <div className="article-action">
                        <Button size="small" onClick={() => addToSelectedList(article.id)}>+ hinzufügen</Button>
                        <Button size="small" onClick={() => removeFromSelectedList(article.id)}>- entfernen</Button>
                    </div>
                </article>
            </li>
        </>
    )

}

export default Article;