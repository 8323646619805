import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Columns } from 'react-bulma-components';

const Dashboard = () => {

    // set document title
    useEffect(() => {
        document.title = 'Dashboard - Mein Konto - Maison Truffe';
    }, []);

    return (
        <section className="section-wrapper">
                <div className="section-inner">
                    <h1>Dashboard</h1>
                    <Columns>
                        <Columns.Column>
                            <Columns>
                                <Columns.Column>Kundennummer:</Columns.Column>
                                <Columns.Column>12345</Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column>Firma:</Columns.Column>
                                <Columns.Column>Opacc Software AG</Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column>Vorname:</Columns.Column>
                                <Columns.Column>Alexander</Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column>Nachname:</Columns.Column>
                                <Columns.Column>Hauck</Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column>PLZ/Ort:</Columns.Column>
                                <Columns.Column>CH-6023 Rothenburg</Columns.Column>
                            </Columns>
                        </Columns.Column>
                        <Columns.Column></Columns.Column>
                    </Columns>
                    <br/>
                    <Columns>
                        <Columns.Column><Button color="info" fullwidth="true">Einstellungen</Button></Columns.Column>
                        <Columns.Column><Button color="info" fullwidth="true">Passwort ändern</Button></Columns.Column>
                        <Columns.Column></Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column><Button color="info" fullwidth="true">Lieferadressen</Button></Columns.Column>
                        <Columns.Column><Button color="info" fullwidth="true">Rechnungsadressen</Button></Columns.Column>
                        <Columns.Column></Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column><Link className="button is-info is-fullwidth" to='/account/bestellungen/'>Meine Bestellungen</Link></Columns.Column>
                        <Columns.Column><Link className="button is-info is-fullwidth" to='/account/retouren/'>Retouren & Servicefälle</Link></Columns.Column>
                        <Columns.Column></Columns.Column>
                    </Columns>
                </div>
            </section>
    )
}

export default Dashboard;