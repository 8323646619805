import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bulma-components';


const ReturnsHeader = () => {

    function confirmCancel() {
        if(window.confirm('Möchten Sie den Retouren-Prozess wirklich abbrechen? Alle Änderungen gehen verloren.')) {
            window.location = '/account/retouren/';
        }
    }

    return (
        <>
            <h1>Retoure/Servicefall erfassen</h1>
            <p>Bitte folgen Sie den einzelnen Schritten durch den Prozess. Bei Fragen finden Sie auf unserer <Link to="/service/retouren">Info-Seite</Link> alle wichtigen Antworten. </p>
            <Button color="danger" onClick={confirmCancel}>abbrechen</Button>
            <br/>
            <br/>
        </>
    )
}

export default ReturnsHeader;