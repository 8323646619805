import React from 'react';
import OffcanvasButton from '../atoms/OffcanvasButton';
import SearchButton from '../atoms/SearchButton';
import SearchInput from '../atoms/SearchInput';
import Logo from '../images/logo.svg';
import { Button } from 'react-bulma-components';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <header id="navigation">
                <div className="header-wrapper">
                    <div className="header-inner">
                        <Link className="brand-logo" to="/">
                            <img src={Logo} alt="Maison Truffe"/>
                        </Link>
                        <div className="header-search">
                            <SearchInput/>
                        </div>
                        <nav className="main-navigation">
                            <ul>
                                <li><NavLink to="/sortiment/kueche/">Küche</NavLink></li>
                                <li><NavLink to="/sortiment/tisch/">Tisch</NavLink></li>
                                <li><NavLink to="/sortiment/bar/">Bar</NavLink></li>
                                <li><NavLink to="/sortiment/haushalt/">Haushalt</NavLink></li>
                                <li><NavLink to="/sortiment/dekoration/">Dekoration</NavLink></li>
                            </ul>
                        </nav>
                        <Button.Group className="header-buttons">
                            <SearchButton />
                            <Link className="button" to="/login" title="Account öffnen">
                                <span className="material-symbols-outlined" aria-hidden="true">person</span>
                                <span className="visuallyhidden">Account öffnen</span>
                            </Link>
                            <Button color="" title="Merkliste öffnen">
                                <span className="material-symbols-outlined" aria-hidden="true">favorite</span>
                                <span className="visuallyhidden">Merkliste öffnen</span>
                            </Button>
                            <Button color="" title="Warenkorb öffnen">
                                <span className="material-symbols-outlined" aria-hidden="true">shopping_cart</span>
                                <span className="visuallyhidden">Warenkorb öffnen</span>
                            </Button>
                        </Button.Group>
                        <OffcanvasButton/>                        
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header