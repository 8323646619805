import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button } from 'react-bulma-components';

const ReturnsOverview = () => {

    // set document title
    useEffect(() => {
        document.title = 'Retouren & Servicefälle - Maison Truffe';
    }, []);

    // "delete" cookies
    const returnsStep1CookieName = 'return-reason';
    const returnsStep2CookieName = 'return-articles';
    const cookies = document.cookie.split(';')
    cookies.forEach((cookie) => {
        if(cookie.trim().startsWith(returnsStep1CookieName)){
            document.cookie = `${returnsStep1CookieName}=;Path=/;Max-Age=-99999999;`;  
        }
        if(cookie.trim().startsWith(returnsStep2CookieName)){
            document.cookie = `${returnsStep2CookieName}=;Path=/;Max-Age=-99999999;`;  
        }
    });

    return (
        <>
            <section className="section-wrapper">
                <div className="section-inner">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/account/dashboard/">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/account/retouren/">Retouren & Servicefälle</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
            <section className="section-wrapper">
                <div className="section-inner">
                    <h1><span className="material-symbols-outlined" style={{'fontSize': '35px'}} aria-hidden="true">construction</span> Retouren & Servicefälle</h1>
                    <Button.Group align="right">
                        <Link className="button is-info" to='/account/retouren/1/'>Neue Retoure / Servicefall erfassen</Link>
                    </Button.Group>
                    <p>2 offene</p>

                    <table>
                        <thead>
                            <tr>
                                <th>Retouren-Nr.</th>
                                <th>Datum</th>
                                <th>Anzahl Artikel</th>
                                <th>Status der Retoure</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Link to="/account/retouren/763972/">763972</Link></td>
                                <td><Link to="/account/retouren/763972/">15.04.2023</Link></td>
                                <td><Link to="/account/retouren/763972/">1</Link></td>
                                <td><Link to="/account/retouren/763972/">In Bearbeitung</Link></td>
                            </tr>
                            <tr>
                                <td><Link to="/account/retouren/382777/">382777</Link></td>
                                <td><Link to="/account/retouren/382777/">02.04.2023</Link></td>
                                <td><Link to="/account/retouren/382777/">2</Link></td>
                                <td><Link to="/account/retouren/382777/">Offen</Link></td>
                            </tr>
                            <tr>
                                <td><Link to="/account/retouren/3232377/">3232377</Link></td>
                                <td><Link to="/account/retouren/3232377/">01.03.2023</Link></td>
                                <td><Link to="/account/retouren/3232377/">3</Link></td>
                                <td><Link to="/account/retouren/3232377/">Abgeschlossen</Link></td>
                            </tr>
                            <tr>
                                <td><Link to="/account/retouren/372173/">372173</Link></td>
                                <td><Link to="/account/retouren/372173/">20.02.2023</Link></td>
                                <td><Link to="/account/retouren/372173/">1</Link></td>
                                <td><Link to="/account/retouren/372173/">Abgeschlossen</Link></td>
                            </tr>
                        </tbody>
                    </table>
                    <br/><br/>
                    <p>
                        <b>Sie haben generelle Fragen zum Retouren-Prozess?</b><br/>
                        Besuchen Sie unsere Info-Seite, welche die wichtigsten Fragen beantwortet: <Link to="/service/retouren/">Info-Seite öffnen.</Link>
                    </p>

                </div>
            </section>
        </>
    )

}

export default ReturnsOverview;